import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/AutoDocLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Name`}</h2>
    <p><strong parentName="p">{`step ca renew`}</strong>{` -- renew a certificate`}</p>
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-raw"
      }}>{`step ca renew <crt-file> <key-file>
[--mtls] [--password-file=<file>] [--out=<file>] [--expires-in=<duration>]
[--force] [--pid=<int>] [--pid-file=<file>] [--signal=<int>]
[--exec=<string>] [--daemon] [--renew-period=<duration>]
[--ca-url=<uri>] [--root=<file>] [--context=<name>]
`}</code></pre>
    <h2>{`Description`}</h2>
    <p><strong parentName="p">{`step ca renew`}</strong>{` command renews the given certificate (with a request to the
certificate authority) and writes the new certificate to disk - either overwriting
`}<inlineCode parentName="p">{`crt-file`}</inlineCode>{` or using a new file when the `}<strong parentName="p">{`--out`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{` flag is used.`}</p>
    <p>{`With the `}<strong parentName="p">{`--daemon`}</strong>{` flag the command will periodically update the given
certificate. By default, it will renew the certificate before 2/3 of the validity
period of the certificate has elapsed. A random jitter is used to avoid multiple
instances running at the same time. The amount of time between renewal and
certificate expiration can be configured using the `}<strong parentName="p">{`--expires-in`}</strong>{` flag, or a
fixed period can be set with the `}<strong parentName="p">{`--renew-period`}</strong>{` flag.`}</p>
    <p>{`The `}<strong parentName="p">{`--daemon`}</strong>{` flag can be combined with `}<strong parentName="p">{`--pid`}</strong>{`, `}<strong parentName="p">{`--signal`}</strong>{`, or `}<strong parentName="p">{`--exec`}</strong>{`
to provide certificate reloads on your services.`}</p>
    <p>{`By default, the renew command authenticates to step-ca using mTLS, except when
the certificate is expired and renewal after expiry is allowed by the CA.`}</p>
    <p>{`There are scenarios where mTLS is not possible: When step-ca is behind a layer 7 proxy,
when the server's leaf certificate EKU is not configured for client authentication,
or when the server is a StepCAS RA for an upstream step-ca server.
For these scenarios, use `}<strong parentName="p">{`--mtls=false`}</strong>{` to force a flow that uses X5C
token-based authentication.`}</p>
    <h2>{`Positional arguments`}</h2>
    <p><inlineCode parentName="p">{`crt-file`}</inlineCode>{`
The certificate in PEM format that we want to renew.`}</p>
    <p><inlineCode parentName="p">{`key-file`}</inlineCode>{`
They key file of the certificate.`}</p>
    <h2>{`Options`}</h2>
    <p><strong parentName="p">{`--mtls`}</strong>{`
Use mTLS to renew a certificate. Use --mtls=false to force the token
authorization flow instead.`}</p>
    <p><strong parentName="p">{`--ca-config`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The certificate authority configuration `}<inlineCode parentName="p">{`file`}</inlineCode>{`. Defaults to
$(step path)/config/ca.json`}</p>
    <p><strong parentName="p">{`-f`}</strong>{`, `}<strong parentName="p">{`--force`}</strong>{`
Force the overwrite of files without asking.`}</p>
    <p><strong parentName="p">{`--offline`}</strong>{`
Creates a certificate without contacting the certificate authority. Offline mode
uses the configuration, certificates, and keys created with `}<strong parentName="p">{`step ca init`}</strong>{`,
but can accept a different configuration file using `}<strong parentName="p">{`--ca-config`}</strong>{` flag.`}</p>
    <p><strong parentName="p">{`--password-file`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The path to the `}<inlineCode parentName="p">{`file`}</inlineCode>{` containing the password to encrypt or decrypt the private key.`}</p>
    <p><strong parentName="p">{`--out`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`, `}<strong parentName="p">{`--output-file`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The new certificate `}<inlineCode parentName="p">{`file`}</inlineCode>{` path. Defaults to overwriting the `}<inlineCode parentName="p">{`crt-file`}</inlineCode>{` positional argument`}</p>
    <p><strong parentName="p">{`--expires-in`}</strong>{`=`}<inlineCode parentName="p">{`duration`}</inlineCode>{`
The amount of time remaining before certificate expiration,
at which point a renewal should be attempted. The certificate renewal will not
be performed if the time to expiration is greater than the `}<strong parentName="p">{`--expires-in`}</strong>{` value.
A random jitter (duration/20) will be added to avoid multiple services hitting the
renew endpoint at the same time. The `}<inlineCode parentName="p">{`duration`}</inlineCode>{` is a sequence of decimal numbers,
each with optional fraction and a unit suffix, such as "300ms", "-1.5h" or "2h45m".
Valid time units are "ns", "us" (or "µs"), "ms", "s", "m", "h".`}</p>
    <p><strong parentName="p">{`--pid`}</strong>{`=`}<inlineCode parentName="p">{`value`}</inlineCode>{`
The process id to signal after the certificate has been renewed. By default the
the SIGHUP (1) signal will be used, but this can be configured with the `}<strong parentName="p">{`--signal`}</strong>{`
flag.`}</p>
    <p><strong parentName="p">{`--pid-file`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The `}<inlineCode parentName="p">{`file`}</inlineCode>{` from which to read the process id that will be signaled after the certificate
has been renewed. By default the SIGHUP (1) signal will be used, but this can be configured with the `}<strong parentName="p">{`--signal`}</strong>{`
flag.`}</p>
    <p><strong parentName="p">{`--signal`}</strong>{`=`}<inlineCode parentName="p">{`number`}</inlineCode>{`
The signal `}<inlineCode parentName="p">{`number`}</inlineCode>{` to send to the selected PID, so it can reload the
configuration and load the new certificate. Default value is SIGHUP (1)`}</p>
    <p><strong parentName="p">{`--exec`}</strong>{`=`}<inlineCode parentName="p">{`command`}</inlineCode>{`
The `}<inlineCode parentName="p">{`command`}</inlineCode>{` to run after the certificate has been renewed.`}</p>
    <p><strong parentName="p">{`--daemon`}</strong>{`
Run the renew command as a daemon, renewing and overwriting the certificate
periodically. By default the daemon will renew a certificate before 2/3 of the
time to expiration has elapsed. The period can be configured using the
`}<strong parentName="p">{`--renew-period`}</strong>{` or `}<strong parentName="p">{`--expires-in`}</strong>{` flags.`}</p>
    <p><strong parentName="p">{`--renew-period`}</strong>{`=`}<inlineCode parentName="p">{`duration`}</inlineCode>{`
The period with which to schedule renewals of the certificate in daemon mode.
Requires the `}<strong parentName="p">{`--daemon`}</strong>{` flag. The `}<inlineCode parentName="p">{`duration`}</inlineCode>{` is a sequence of decimal numbers,
each with optional fraction and a unit suffix, such as "300ms", "1.5h", or "2h45m".
Valid time units are "ns", "us" (or "µs"), "ms", "s", "m", "h".`}</p>
    <p><strong parentName="p">{`--ca-url`}</strong>{`=`}<inlineCode parentName="p">{`URI`}</inlineCode>{`
`}<inlineCode parentName="p">{`URI`}</inlineCode>{` of the targeted Step Certificate Authority.`}</p>
    <p><strong parentName="p">{`--root`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The path to the PEM `}<inlineCode parentName="p">{`file`}</inlineCode>{` used as the root certificate authority.`}</p>
    <p><strong parentName="p">{`--context`}</strong>{`=`}<inlineCode parentName="p">{`name`}</inlineCode>{`
The context `}<inlineCode parentName="p">{`name`}</inlineCode>{` to apply for the given command.`}</p>
    <h2>{`Examples`}</h2>
    <p>{`Renew a certificate with the configured CA:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca renew internal.crt internal.key
Would you like to overwrite internal.crt [Y/n]: y
`}</code></pre>
    <p>{`Renew a certificate without overwriting the previous certificate:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca renew --out renewed.crt internal.crt internal.key
`}</code></pre>
    <p>{`Renew a certificate forcing the overwrite of the previous certificate:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca renew --force internal.crt internal.key
`}</code></pre>
    <p>{`Renew a certificate using the token flow instead of mTLS:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca renew --mtls=false --force internal.crt internal.key
`}</code></pre>
    <p>{`Renew a certificate providing the `}<inlineCode parentName="p">{`--ca-url`}</inlineCode>{` and `}<inlineCode parentName="p">{`--root`}</inlineCode>{` flags:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca renew --ca-url https://ca.smallstep.com:9000 \\
  --root /path/to/root_ca.crt internal.crt internal.key
Would you like to overwrite internal.crt [Y/n]: y
`}</code></pre>
    <p>{`Renew skipped because it was too early:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca renew --expires-in 8h internal.crt internal.key
certificate not renewed: expires in 10h52m5s
`}</code></pre>
    <p>{`Renew the certificate before 2/3 of the validity has passed:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca renew --daemon internal.crt internal.key
`}</code></pre>
    <p>{`Renew the certificate before 8 hours and 30m of the expiration time:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca renew --daemon --expires-in 8h30m internal.crt internal.key
`}</code></pre>
    <p>{`Renew the certificate every 16h:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca renew --daemon --renew-period 16h internal.crt internal.key
`}</code></pre>
    <p>{`Renew the certificate and reload nginx:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca renew --daemon --exec "nginx -s reload" internal.crt internal.key
`}</code></pre>
    <p>{`Renew the certificate and convert it to DER:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca renew --daemon --renew-period 16h \\
  --exec "step certificate format --force --out internal.der internal.crt" \\
  internal.crt internal.key
`}</code></pre>
    <p>{`Renew a certificate using the offline mode, requires the configuration
files, certificates, and keys created with `}<strong parentName="p">{`step ca init`}</strong>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca renew --offline internal.crt internal.key
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      